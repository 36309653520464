import currency from 'currency.js';

export function currencyFormat(
  value: string,
  options: {
    currencySymbol?: string;
    precision?: number;
  } = { currencySymbol: '€', precision: 2 },
): string {
  const result = currency(value, {
    symbol: '',
    decimal: '.',
    precision: options.precision,
  });

  return (
    result.format({
      symbol: '',
      decimal: ',',
      separator: '.',
      precision: options.precision,
    }) +
    ' ' +
    options.currencySymbol
  );
}
